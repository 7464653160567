import useDocumentTitle from "../../useDocumentTitle";
import TopCards from "components/Dashboard/TopCards";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import Withdrawal from "components/Withdrawal";
import Deposit from "components/Deposit";
import Toast from "components/Toast";
import MyTickets from "components/Tickets";
import { useApp } from "../../AppContext";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";

const Dashboard = () => {
  const [jackpots, setJackpots] = useState([]);
  const [player, setPlayer] = useState([]);
  const { token, baseDomain } = useApp();
  const selectedLanguage = localStorage.getItem("i18nextLng");

  const { t } = useTranslation();
  useDocumentTitle(`${t("dashboard.title")} | ${t("site-name")}`);
  const notify = React.useCallback((type, message) => {
    Toast({ type, message });
  }, []);

  const lotteries = React.useMemo(
    () => [
      { value: "megamillions", text: "Mega Millions" },
      { value: "powerball", text: "Powerball" },
      { value: "euromillions", text: "Euro Millions" },
      { value: "eurojackpot", text: "Eurojackpot" },
      { value: "el-gordo-primitiva", text: "El Gordo" },
      { value: "la-primitiva", text: "La Primitiva" },
      { value: "megasena", text: "Mega-Sena" },
      { value: "superenalotto", text: "SuperEnalotto" },
      { value: "lotto-america", text: "Lotto America" },
      { value: "lotto-6aus49", text: "Lotto 6aus49" },
      { value: "sat-lotto-au", text: "Saturday Lotto" },
      { value: "powerball-au", text: "Powerball AU" },
      { value: "lotto-fr", text: "French Lotto" },
      { value: "lotto-uk", text: "UK Lottery" },
      { value: "thunderball", text: "Thunderball" },
      { value: "set-for-life", text: "Set For Life" },
    ],
    []
  );

  const fetchProfileData = React.useCallback(async () => {
    try {
      await fetch("api/v1/users/profile", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then((data) => {
          setPlayer(data);
        })
        .catch((error) => {
          notify("error", error.message);
          if (error.message === "Unauthorized")
            window.location.href = `https://${baseDomain}${
              selectedLanguage === "en"
                ? "/login"
                : `/${selectedLanguage}/login`
            }`;
        });
    } catch (error) {
      notify("error", error.message);
    }
  }, [baseDomain, notify, selectedLanguage, token]);

  const fetchJackpotData = React.useCallback(async () => {
    const cacheKey = "jackpotData";
    const cacheTTL = 2 * 60 * 60 * 1000;
    const cachedData = localStorage.getItem(cacheKey);

    if (cachedData) {
      const parsedCache = JSON.parse(cachedData);
      const currentTime = new Date().getTime();

      if (currentTime - parsedCache.timestamp < cacheTTL) {
        setJackpots(parsedCache.data);
        return;
      }
    }

    await fetch("https://api.simbalotto.com/draws", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        return response.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((data) => {
        const types = [
          "megamillions",
          "powerball",
          "euromillions",
          "eurojackpot",
          "el-gordo-primitiva",
          "la-primitiva",
          "megasena",
          "superenalotto",
          "lotto-america",
          "lotto-6aus49",
          "sat-lotto-au",
          "powerball-au",
          "lotto-fr",
          "lotto-uk",
          "thunderball",
          "set-for-life",
        ];

        const imagePaths = {
          eurojackpot: "eurojackpot.webp",
          euromillions: "euromillions.webp",
          superenalotto: "superenalotto.webp",
          megamillions: "megamillions.webp",
          megasena: "megasena.webp",
          powerball: "powerball.webp",
          thunderball: "thunderball.webp",
          "lotto-fr": "french-lotto.webp",
          "lotto-6aus49": "german-lotto.webp",
          "el-gordo-primitiva": "el-gordo.webp",
          "la-primitiva": "la-primitiva.webp",
          "powerball-au": "powerball-au.webp",
          "sat-lotto-au": "saturday-lotto.webp",
          "lotto-america": "lotto-america.webp",
          "lotto-uk": "uk-lottery.webp",
          "set-for-life": "set-for-life-uk.webp",
        };

        const playUrls = {
          eurojackpot: `https://${baseDomain}${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/eurojackpot`,
          euromillions: `https://${baseDomain}${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/euromillions`,
          superenalotto: `https://${baseDomain}${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/superenalotto`,
          megamillions: `https://${baseDomain}${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/mega-millions`,
          megasena: `https://${baseDomain}${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/mega-sena`,
          powerball: `https://${baseDomain}${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/powerball`,
          thunderball: `https://${baseDomain}${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/thunderball`,
          "lotto-fr": `https://${baseDomain}${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/lotto-fr`,
          "lotto-6aus49": `https://${baseDomain}${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/lotto-6aus49`,
          "el-gordo-primitiva": `https://${baseDomain}${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/el-gordo-primitiva`,
          "la-primitiva": `https://${baseDomain}${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/la-primitiva`,
          "powerball-au": `https://${baseDomain}${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/powerball-au`,
          "sat-lotto-au": `https://${baseDomain}${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/saturday-lotto-au`,
          "lotto-america": `https://${baseDomain}${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/lotto-america`,
          "lotto-uk": `https://${baseDomain}${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/lott-uk`,
          "set-for-life": `https://${baseDomain}${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/set-for-life-uk`,
        };

        const jackpots = data.filter(
          (j) => !j.prizes && types.includes(j.type)
        );

        jackpots.forEach((jackpot) => {
          jackpot.imagePath = imagePaths[jackpot.type];
          const date = new Date(jackpot.date);
          jackpot.date = date.toDateString().split(" ").slice(0, 4).join(" ");
          jackpot.name = lotteries.find(
            (lottery) => lottery.value === jackpot.type
          )?.text;
          jackpot.playUrl = playUrls[jackpot.type];
        });

        localStorage.setItem(
          cacheKey,
          JSON.stringify({ data: jackpots, timestamp: new Date().getTime() })
        );

        setJackpots(jackpots);
      })
      .catch((error) => {
        notify("error", error.message);
        if (error.message === "Unauthorized")
          window.location.href = `https://${baseDomain}${
            selectedLanguage === "en" ? "/login" : `/${selectedLanguage}/login`
          }`;
      });
  }, [baseDomain, lotteries, notify, selectedLanguage, token]);

  useEffect(() => {
    fetchProfileData();
    fetchJackpotData();
  }, [fetchJackpotData, fetchProfileData]);

  return (
    <>
      <TopCards />

      <div className="row mb-3">
        <div className="order-xl-1 mb-2 col-xl-6">
          <Deposit player={player} />
        </div>

        <div className="order-xl-2 col-xl-6">
          <Withdrawal player={player} />
        </div>
      </div>

      <Swiper
        navigation={true}
        modules={[Navigation, Autoplay]}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        className="px-5"
        style={{ zIndex: 0 }}
        spaceBetween={10}
        slidesPerView={1}
        breakpoints={{
          576: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          994: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        }}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        {jackpots &&
          jackpots.map((jackpot, index) => (
            <SwiperSlide key={index}>
              <div className="card">
                <div className="row">
                  <div className="col-8">
                    <div className="card-body">
                      <h5 className="card-title">{jackpot.name}</h5>
                      <p
                        className="card-text text-dark"
                        style={{ fontWeight: "700" }}
                      >
                        {Number(jackpot?.jackpot?.total)?.toLocaleString(
                          "de-DE",
                          {
                            style: "currency",
                            currency: "EUR",
                            minimumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-4 d-flex justify-content-end">
                    <img
                      src={jackpot.imagePath}
                      alt={jackpot.name}
                      style={{
                        position: "absolute",
                        maxWidth: "100px",
                      }}
                    />
                  </div>
                </div>

                <hr className="my-3" />

                <div className="row mb-2">
                  <div className="col-md-8 col-6">
                    <p className="mx-2">{jackpot.date}</p>
                  </div>
                  <div className="col-md-4 col-6 d-flex justify-content-end">
                    <a
                      className="btn w-100 btn-simbalotto-orange text-uppercase mx-2"
                      href={jackpot.playUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("menu.play")}
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>

      <div className="row mt-3">
        <div className="col-xl-12 mb-5 mb-xl-0" style={{ zIndex: 0 }}>
          <MyTickets />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
