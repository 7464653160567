import * as yup from "yup";
import Select from "react-select";
import Toast from "components/Toast";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const Deposit = ({ player }) => {
  const [paymentmethod, setPaymentMethod] = useState(null);
  const [showBilling, setShowBilling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(null);

  const notify = React.useCallback((type, message) => {
    Toast({ type, message });
  }, []);
  const token = document.cookie
    .split(";")
    .find((cookie) => {
      return cookie.startsWith("simba_id");
    })
    ?.split("=")[1];
  const { t } = useTranslation();

  const withdrawalSchema = yup.object().shape({
    amount: yup
      .number()
      .typeError(t("user-profile.errors.required.amount"))
      .required(t("user-profile.errors.required.amount"))
      .min(10, t("user-profile.errors.min.amount")),
  });

  const billingSchema = yup.object().shape({
    crypto_address: yup
      .string()
      .test(
        "crypto_address",
        t("user-profile.errors.required.crypto_address"),
        (value) => {
          if (showBilling && paymentmethod === "cryptocurrency") {
            return value !== "";
          }
          return true;
        }
      ),
    crypto_network: yup
      .string()
      .test(
        "crypto_network",
        t("user-profile.errors.required.crypto_network"),
        (value) => {
          if (showBilling && paymentmethod === "cryptocurrency") {
            return value !== "";
          }
          return true;
        }
      ),
    crypto_coin: yup
      .string()
      .test(
        "crypto_coin",
        t("user-profile.errors.required.crypto_coin"),
        (value) => {
          if (showBilling && paymentmethod === "cryptocurrency") {
            return value !== "";
          }
          return true;
        }
      ),
    banker_full_name: yup
      .string()
      .test(
        "banker_full_name",
        t("user-profile.errors.required.banker_full_name"),
        (value) => {
          if (showBilling && paymentmethod === "bank") {
            return value !== "";
          }
          return true;
        }
      ),
    bank_account: yup
      .string()
      .test(
        "bank_account",
        t("user-profile.errors.required.bank_account"),
        (value) => {
          if (showBilling && paymentmethod === "bank") {
            return value !== "";
          }
          return true;
        }
      ),
    bank_name: yup
      .string()
      .test(
        "bank_name",
        t("user-profile.errors.required.bank_name"),
        (value) => {
          if (showBilling && paymentmethod === "bank") {
            return value !== "";
          }
          return true;
        }
      ),
    bank_swift: yup
      .string()
      .test(
        "bank_swift",
        t("user-profile.errors.required.bank_swift"),
        (value) => {
          if (showBilling && paymentmethod === "bank") {
            return value !== "";
          }
          return true;
        }
      ),
  });

  const kycSchema = yup.object().shape({
    kyc_doc: yup.string().required(t("user-profile.errors.required.kyc-doc")),
    kyc_doc_file: yup
      .mixed()
      .test(
        "required",
        t("user-profile.errors.required.attach-doc"),
        (value) => {
          if (value.length > 0) {
            return true;
          }
          return false;
        }
      ),
  });

  const {
    register: billingRegister,
    handleSubmit: billingSubmit,
    reset: billingReset,
    formState: { errors: billingErrors },
  } = useForm({
    resolver: yupResolver(billingSchema),
  });

  const {
    register: withdrawalRegister,
    handleSubmit: withdrawalSubmit,
    reset: withdrawalReset,
    trigger: withdrawalTrigger,
    getValues: withdrawalGetValues,
    formState: { errors: withdrawalErrors },
  } = useForm({
    resolver: yupResolver(withdrawalSchema),
  });

  const {
    register: kycRegister,
    handleSubmit: kycSubmit,
    reset: kycReset,
    control: kycControl,
    formState: { errors: kycErrors },
  } = useForm({
    resolver: yupResolver(kycSchema),
  });

  const handleWithdrawal = async (e) => {
    e.preventDefault();
  };

  const handleWithdrawalRequest = async (data) => {
    try {
      setLoading(true);
      await fetch("api/v1/users/withdraw", {
        method: "POST",
        body: JSON.stringify({
          amount: amount,
          method: paymentmethod,
          ...data,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then((data) => {
          withdrawalReset();
          billingReset();
          notify("success", data.message);
          document.getElementById("closeWithdrawModal").click();
        })
        .catch((error) => notify("error", error.message))
        .finally(() => setLoading(false));
    } catch (error) {
      notify("error", error.message);
    }
  };

  const submitKYCDocument = async (data) => {
    const formData = new FormData();
    formData.append("kyc_doc", data.kyc_doc);
    formData.append("file", data.kyc_doc_file[0]);

    try {
      await fetch("api/v1/users/kyc", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      })
        .then(async (response) => {
          if (response.ok) {
            document.getElementById("kycModal").click();
            document.getElementById("kycPendingModal").click();
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then((data) => {
          notify("success", data.message);
          kycReset();
        })
        .catch((error) => notify("error", error.message));
    } catch (error) {
      notify("error", error.message);
    }
  };

  const kycDocs = [
    {
      value: t("user-profile.withdrawal.kyc-docs.national-id"),
      label: t("user-profile.withdrawal.kyc-docs.national-id"),
    },
    {
      value: t("user-profile.withdrawal.kyc-docs.passport"),
      label: t("user-profile.withdrawal.kyc-docs.passport"),
    },
    {
      value: t("user-profile.withdrawal.kyc-docs.drivers-license"),
      label: t("user-profile.withdrawal.kyc-docs.drivers-license"),
    },
  ];

  return (
    <>
      {/* withdrawal input */}
      <div className="card bg-secondary">
        <div className="card-header border-0 bg-simbalotto-green">
          <h3 className="mb-0 text-capitalize text-dark">
            {t("user-profile.headings.withdraw")}
            {player?.wallet?.balance < 10 ? (
              <span className="text-danger">
                ({t("user-profile.notifications.withdraw-disabled")})
              </span>
            ) : null}
          </h3>
        </div>
        <div className="card-body">
          <form onSubmit={withdrawalSubmit(handleWithdrawal)}>
            <h6 className="heading-small text-muted mb-4">
              {t("user-profile.headings.withdraw-info")}
            </h6>
            <div className="pl-lg-4">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label
                      className="form-control-label text-muted"
                      htmlFor="input-withdraw-amount"
                    >
                      {t("user-profile.fields.amount")}
                    </label>
                    <input
                      className="form-control form-control-alternative"
                      id="input-withdraw-amount"
                      placeholder="Amount"
                      type="number"
                      name="withdraw_amount"
                      {...withdrawalRegister("amount")}
                    />

                    {withdrawalErrors.amount && (
                      <span className="text-danger">
                        {withdrawalErrors.amount.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 mt-auto">
                  <div className="form-group">
                    <button
                      className="btn btn-simbalotto-orange text-dark"
                      type="submit"
                      onClick={async (e) => {
                        const result = await withdrawalTrigger("amount");
                        result && setAmount(withdrawalGetValues("amount"));
                        result && e.target.setAttribute("data-toggle", "modal");
                        result &&
                          e.target.setAttribute(
                            "data-target",
                            "#withdrawModal"
                          );
                      }}
                      disabled={
                        Number(player?.wallet?.balance) < 10
                          ? true
                          : loading
                          ? true
                          : false
                      }
                    >
                      <span className="btn-inner--icon text-dark">
                        <i className="fas fa-wallet" />
                      </span>
                      <span className="btn-inner--text text-dark">
                        {t("user-profile.buttons.withdraw")}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* withdrawal modal */}
      <div
        className="modal fade"
        id="withdrawModal"
        tabIndex="-1"
        aria-labelledby="withdrawModalLabel"
        aria-hidden="true"
        bs-backdrop="static"
        bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-capitalize"
                id="withdrawModalCenterTitle"
              >
                {t("user-profile.withdrawal.title")}
              </h5>
              <span
                aria-hidden="true"
                className="close fa-2x"
                data-dismiss="modal"
                aria-label="Close"
                role="button"
                id="closeWithdrawModal"
              >
                &times;
              </span>
            </div>
            <div className="modal-body">
              <div className="row">
                <label
                  className="col-6 mb-2"
                  role="button"
                  htmlFor="bank"
                  onClick={() => {
                    setPaymentMethod("bank");
                    setShowBilling(true);
                  }}
                  {...(player.kyc_status === null ||
                  player.kyc_status === "" ||
                  player.kyc_status === "rejected"
                    ? {
                        "data-toggle": "modal",
                        "data-target": "#kycModal",
                      }
                    : player.kyc_status === "submitted"
                    ? {
                        "data-toggle": "modal",
                        "data-target": "#kycPendingModal",
                      }
                    : {
                        "data-toggle": "modal",
                        "data-target": "#withdrawalModal",
                      })}
                >
                  <div className="card bg-secondary shadow">
                    <div
                      className={`card-body border-0  
                      ${paymentmethod === "bank" ? "bg-simbalotto-green" : ""}
                    `}
                    >
                      <div className="row align-items-center">
                        <div className="col-12 text-center">
                          <i className="fas fa-university fa-3x text-primary" />
                        </div>
                        <div className="col-12">
                          <h3 className="mb-0 text-center">
                            {t("user-profile.withdrawal.bank")}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    type="radio"
                    name="method"
                    value="bank"
                    id="bank"
                    hidden
                  />
                </label>

                <label
                  className="col-6 mb-2"
                  role="button"
                  htmlFor="cryptocurrency"
                  onClick={() => {
                    setPaymentMethod("cryptocurrency");
                    setShowBilling(true);
                  }}
                >
                  <div className="card bg-secondary shadow">
                    <div
                      className={`card-body border-0  
                      ${
                        paymentmethod === "cryptocurrency"
                          ? "bg-simbalotto-green"
                          : ""
                      }
                    `}
                    >
                      <div className="row align-items-center">
                        <div className="col-12 text-center">
                          <i className="fa-brands fa-bitcoin fa-3x text-primary" />
                        </div>
                        <div className="col-12">
                          <h3 className="mb-0 text-center">
                            {t("user-profile.withdrawal.cryptocurrency")}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    type="radio"
                    name="method"
                    value="cryptocurrency"
                    id="cryptocurrency"
                    hidden
                  />
                </label>
              </div>

              <form
                className="row"
                onSubmit={billingSubmit(handleWithdrawalRequest)}
              >
                {showBilling && (
                  <>
                    {paymentmethod === "bank" && (
                      <>
                        {/* input for banker_full_name */}
                        <div className="col-12">
                          <label
                            className="form-control-label text-dark"
                            htmlFor="banker_full_name"
                          >
                            {t("user-profile.withdrawal.banker_full_name")}
                          </label>
                          <input
                            className="form-control form-control-alternative"
                            id="banker_full_name"
                            placeholder={t(
                              "user-profile.withdrawal.first-name"
                            )}
                            type="text"
                            defaultValue={player.first_name || ""}
                            {...billingRegister("banker_full_name")}
                          />

                          <span className="text-danger">
                            {billingErrors?.banker_full_name?.message}
                          </span>
                        </div>

                        {/* bank_account */}
                        <div className="col-12">
                          <label
                            className="form-control-label text-dark"
                            htmlFor="bank_account"
                          >
                            {t("user-profile.withdrawal.bank_account")}
                          </label>
                          <input
                            className="form-control form-control-alternative"
                            id="bank_account"
                            placeholder={t(
                              "user-profile.withdrawal.bank_account"
                            )}
                            type="text"
                            {...billingRegister("bank_account")}
                          />

                          <span className="text-danger">
                            {billingErrors?.bank_account?.message}
                          </span>
                        </div>

                        {/* input for bank_name */}
                        <div className="col-12">
                          <label
                            className="form-control-label text-dark"
                            htmlFor="bank_name"
                          >
                            {t("user-profile.withdrawal.bank_name")}
                          </label>
                          <input
                            className="form-control form-control-alternative"
                            id="bank_name"
                            placeholder={t("user-profile.withdrawal.bank_name")}
                            type="text"
                            {...billingRegister("bank_name")}
                          />

                          <span className="text-danger">
                            {billingErrors?.bank_name?.message}
                          </span>
                        </div>

                        {/* bank_swift */}
                        <div className="col-12">
                          <label
                            className="form-control-label text-dark"
                            htmlFor="bank_swift"
                          >
                            {t("user-profile.withdrawal.bank_swift")}
                          </label>
                          <input
                            className="form-control form-control-alternative"
                            id="bank_swift"
                            placeholder={t(
                              "user-profile.withdrawal.bank_swift"
                            )}
                            type="text"
                            {...billingRegister("bank_swift")}
                          />

                          <span className="text-danger">
                            {billingErrors?.bank_swift?.message}
                          </span>
                        </div>
                      </>
                    )}

                    {/* crypto_address */}
                    {paymentmethod === "cryptocurrency" && (
                      <>
                        <div className="col-12">
                          <label
                            className="form-control-label text-dark"
                            htmlFor="crypto_address"
                          >
                            {t("user-profile.withdrawal.crypto_address")}
                          </label>
                          <input
                            className="form-control form-control-alternative"
                            id="crypto_address"
                            placeholder={t(
                              "user-profile.withdrawal.crypto_address"
                            )}
                            type="text"
                            {...billingRegister("crypto_address")}
                          />

                          <span className="text-danger">
                            {billingErrors?.crypto_address?.message}
                          </span>
                        </div>

                        {/* crypto network */}
                        <div className="col-12">
                          <label
                            className="form-control-label text-dark"
                            htmlFor="crypto_network"
                          >
                            {t("user-profile.withdrawal.crypto_network")}
                          </label>
                          <input
                            className="form-control form-control-alternative"
                            id="crypto_network"
                            placeholder={t(
                              "user-profile.withdrawal.crypto_network"
                            )}
                            type="text"
                            {...billingRegister("crypto_network")}
                          />

                          <span className="text-danger">
                            {billingErrors?.crypto_network?.message}
                          </span>
                        </div>

                        {/* crypto coin */}
                        <div className="col-12">
                          <label
                            className="form-control-label text-dark"
                            htmlFor="crypto_coin"
                          >
                            {t("user-profile.withdrawal.crypto_coin")}
                          </label>
                          <input
                            className="form-control form-control-alternative"
                            id="crypto_coin"
                            placeholder={t(
                              "user-profile.withdrawal.crypto_coin"
                            )}
                            type="text"
                            {...billingRegister("crypto_coin")}
                          />

                          <span className="text-danger">
                            {billingErrors?.crypto_coin?.message}
                          </span>
                        </div>
                      </>
                    )}
                  </>
                )}

                <div className="col-12 mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    disabled={loading}
                  >
                    {t("user-profile.proceed")}
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm ml-2"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* kyc modal */}
      <div
        className="modal fade"
        id="kycModal"
        tabIndex="-1"
        aria-labelledby="kycModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-capitalize"
                id="kycModalCenterTitle"
              >
                {t("user-profile.withdrawal.kyc-title")}
              </h5>

              <span
                aria-hidden="true"
                className="close fa-2x"
                data-dismiss="modal"
                aria-label="Close"
                role="button"
              >
                &times;
              </span>
            </div>
            <div className="modal-body">
              <p>{t("user-profile.withdrawal.kyc-text")}</p>
              <ul>
                {kycDocs.map((doc) => (
                  <li key={doc.value}>{doc.label}</li>
                ))}
              </ul>
              <div className="col-12">
                <form onSubmit={kycSubmit(submitKYCDocument)}>
                  <div className="col-12">
                    <label
                      className="form-control-label text-muted"
                      htmlFor="input-kyc-doc"
                    >
                      {t("user-profile.withdrawal.select-kyc-doc")}
                    </label>
                    <Controller
                      name="kyc_doc"
                      control={kycControl}
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          inputRef={ref}
                          value={kycDocs.filter((c) => c.value === value)}
                          onChange={(val) => onChange(val.value)}
                          options={kycDocs}
                          placeholder={t(
                            "user-profile.withdrawal.select-kyc-doc"
                          )}
                        />
                      )}
                    />

                    {kycErrors.kyc_doc && (
                      <span className="text-danger">
                        {kycErrors.kyc_doc.message}
                      </span>
                    )}
                  </div>

                  <div className="col-12 mt-3">
                    <label
                      className="form-control-label text-muted"
                      htmlFor="input-kyc-doc-file"
                    >
                      {t("user-profile.withdrawal.attach-kyc-doc")}
                    </label>
                    <input
                      className="form-control-file form-control-alternative"
                      id="input-kyc-doc-file"
                      type="file"
                      name="kyc_doc_file"
                      {...kycRegister("kyc_doc_file")}
                    />

                    {kycErrors.kyc_doc_file && (
                      <span className="text-danger">
                        {kycErrors.kyc_doc_file.message}
                      </span>
                    )}
                  </div>

                  <div className="col-12 mt-3">
                    <button
                      className="btn btn-simbalotto-orange text-dark"
                      type="submit"
                    >
                      <span className="btn-inner--text text-dark">
                        {t("user-profile.withdrawal.submit-kyc")}
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* pending kyc modal */}
      <div
        className="modal fade"
        id="kycPendingModal"
        tabIndex="-1"
        aria-labelledby="kycPendingModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-capitalize"
                id="kycPendingModalCenterTitle"
              >
                {t("user-profile.withdrawal.kyc-title")}
              </h5>

              <span
                aria-hidden="true"
                className="close fa-2x"
                data-dismiss="modal"
                aria-label="Close"
                role="button"
              >
                &times;
              </span>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 text-center">
                  <i className="fas fa-check-circle fa-6x fa-bounce text-success" />
                </div>
                <div className="col-12 mt-2">
                  <h3 className="mb-0 text-center">
                    {t("user-profile.withdrawal.kyc-pending")}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Deposit;
