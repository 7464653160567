import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";
import MyTickets from "components/Tickets";

const MyLotteries = () => {
  const { t } = useTranslation();
  useDocumentTitle(`${t("lotteries.my-lotteries")} | ${t("site-name")}`);

  return (
    <div className="row">
      <div className="col-12" style={{ zIndex: 0 }}>
        <MyTickets />
      </div>
    </div>
  );
};

export default MyLotteries;
