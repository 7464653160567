import useDocumentTitle from "../../useDocumentTitle";
import { Editor } from "@tinymce/tinymce-react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import React, { useRef } from "react";
import Toast from "components/Toast";

const CreateTicket = () => {
  const { t } = useTranslation();
  useDocumentTitle(`${t("tickets.create-ticket")} | ${t("site-name")}`);
  const editorRef = useRef(null);
  const token = document.cookie
    .split(";")
    .find((cookie) => {
      return cookie.startsWith("simba_id");
    })
    ?.split("=")[1];
  const history = useHistory();
  const notify = React.useCallback((type, message) => {
    Toast({ type, message });
  }, []);

  const submitTicket = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("description", editorRef.current.getContent());
    const data = Object.fromEntries(formData.entries());
    fetch("/api/v1/tickets", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        return response.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((data) => {
        notify("success", data.message);
        history.push("/support-tickets");
      })
      .catch((error) => {
        notify("error", error.message);
      });
  };

  const image_upload_handler = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open("POST", "/api/v1/tickets/uploadImage");
      xhr.setRequestHeader("Authorization", "Bearer " + token);

      xhr.upload.onprogress = (e) => {
        progress((e.loaded / e.total) * 100);
      };

      xhr.onload = () => {
        if (xhr.status === 403) {
          reject({ message: "HTTP Error: " + xhr.status, remove: true });
          return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          reject("HTTP Error: " + xhr.status);
          return;
        }

        const json = JSON.parse(xhr.responseText);

        if (!json || typeof json.location != "string") {
          reject("Invalid JSON: " + xhr.responseText);
          return;
        }

        resolve(json.location);
      };

      xhr.onerror = () => {
        reject(
          "Image upload failed due to a XHR Transport error. Code: " +
            xhr.status
        );
      };

      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());

      xhr.send(formData);
    });

  const departments = [
    { value: "general", label: t("tickets.departments.general") },
    { value: "account", label: t("tickets.departments.account") },
    { value: "technical", label: t("tickets.departments.technical") },
    { value: "deposit", label: t("tickets.departments.deposit") },
    { value: "withdrawal", label: t("tickets.departments.withdrawal") },
    { value: "bonus", label: t("tickets.departments.bonus") },
    { value: "other", label: t("tickets.departments.other") },
  ];

  return (
    <form onSubmit={submitTicket}>
      <div className="row">
        <div className="col-xl-6">
          <div className="form-group">
            <label
              className="form-control-label text-white text-capitalize"
              htmlFor="department"
            >
              {t("tickets.fields.department")}
            </label>
            <select
              className="form-control text-capitalize"
              id="department"
              name="department"
              required
            >
              {departments.map((department) => (
                <option key={department.value} value={department.value}>
                  {department.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="form-group">
            <label
              className="form-control-label text-white text-capitalize"
              htmlFor="subject"
            >
              {t("tickets.fields.subject")}
            </label>
            <input
              className="form-control"
              id="subject"
              name="subject"
              placeholder="Subject"
              type="text"
              required
            />
          </div>
        </div>
        <div className="col-xl-12">
          <Editor
            onInit={(evt, editor) => (editorRef.current = editor)}
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            init={{
              min_height: 500,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
                "help",
                "wordcount",
                "autoresize",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | image | help",
              a11y_advanced_options: true,
              image_title: true,
              automatic_uploads: true,
              file_picker_types: "file image media",
              images_upload_handler: image_upload_handler,
              block_unsupported_drop: true,
              default_link_target: "_blank",
              file_picker_callback: function (cb, value, meta) {
                var input = document.createElement("input");
                input.setAttribute("type", "file");
                input.setAttribute("accept", "image/*");
                input.onchange = function () {
                  var file = this.files[0];

                  var reader = new FileReader();
                  reader.onload = function () {
                    var id = "supportTicket" + new Date().getTime();
                    var blobCache = editorRef.current.editorUpload.blobCache;
                    var base64 = reader.result.split(",")[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);

                    /* call the callback and populate the Title field with the file name */
                    cb(blobInfo.blobUri(), { title: file.name });
                  };
                  reader.readAsDataURL(file);
                };
                input.click();
              },
            }}
          />
        </div>
        <div className="col-xl-12">
          <button
            type="submit"
            className="btn btn-primary mt-3 text-capitalize"
          >
            {t("buttons.submit")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreateTicket;
