import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";

const AppContext = createContext();

export const useApp = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [balance, setBalance] = useState(0);
  const [novu, setNovu] = useState(null);
  const [user_id, setUser_id] = useState(null);
  const baseDomain = window.location.hostname.replace(/^account\./, "");

  const token = document.cookie
    .split(";")
    .find((cookie) => {
      return cookie.startsWith("simba_id");
    })
    ?.split("=")[1];

  const fetchBalance = useCallback(async () => {
    try {
      await fetch("/api/v1/users/balance", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          setBalance(result.data);
          setNovu(result.novuData);
          setUser_id(result.novuData?.subscriberId);
        });
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);

  const value = {
    balance,
    token,
    novu,
    user_id,
    baseDomain,
    refetchBalance: fetchBalance,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
