import DatePickerCustom from "components/Table/datePicker";
import TableComponent from "components/Table/index";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useApp } from "../../AppContext";
import React, { useState } from "react";
import Toast from "components/Toast";

const MyTickets = () => {
  const [loading, setLoading] = useState(false);
  const { token, baseDomain } = useApp();
  const history = useHistory();
  const { t } = useTranslation();
  const notify = React.useCallback((type, message) => {
    Toast({ type, message });
  }, []);
  const selectedLanguage = localStorage.getItem("i18nextLng");

  const lotteries = React.useMemo(
    () => [
      { value: "megamillions", text: "Mega Millions" },
      { value: "powerball", text: "Powerball" },
      { value: "euromillions", text: "Euro Millions" },
      { value: "eurojackpot", text: "Eurojackpot" },
      { value: "el-gordo-primitiva", text: "El Gordo" },
      { value: "la-primitiva", text: "La Primitiva" },
      { value: "megasena", text: "Mega-Sena" },
      { value: "superenalotto", text: "SuperEnalotto" },
      { value: "lotto-america", text: "Lotto America" },
      { value: "lotto-6aus49", text: "Lotto 6aus49" },
      { value: "sat-lotto-au", text: "Saturday Lotto" },
      { value: "powerball-au", text: "Powerball AU" },
      { value: "lotto-fr", text: "French Lotto" },
      { value: "lotto-uk", text: "UK Lottery" },
      { value: "lotto-ny-us", text: "New York Lotto" },
      { value: "thunderball", text: "Thunderball" },
      { value: "set-for-life", text: "Set For Life" },
    ],
    []
  );

  const statuses = React.useMemo(
    () => [
      { value: "pending-draw", text: "Pending Draw" },
      { value: "won", text: "Won" },
      { value: "lost", text: "Lost" },
    ],
    []
  );

  const addToCart = React.useCallback(
    async (data) => {
      try {
        let form_data = new FormData();
        form_data.append("action", "postTicketsAPI");
        form_data.append("postData", JSON.stringify(data.postData));
        form_data.append("draw_data", JSON.stringify(data.draw_data));
        form_data.append("lottery", data.lottery);
        form_data.append("lang", selectedLanguage);
        form_data.append("product_id", data.product_id);

        await fetch(`https://${baseDomain}/wp-admin/admin-ajax.php`, {
          method: "POST",
          body: form_data,
          credentials: "include",
        })
          .then(async (response) => {
            if (response.ok) {
              return response.json();
            }
            const text = await response.json();
            throw new Error(text.message || text);
          })
          .then(async (dt) => {
            window.location.replace(dt.data.redirect_url);
          });
      } catch (error) {
        console.error("Error adding to cart:", error);
        notify("error", error.message);
      }
    },
    [baseDomain, notify, selectedLanguage]
  );

  const buyAgain = React.useCallback(
    async (transactionId) => {
      if (!transactionId) return;

      try {
        setLoading(true);
        await fetch("/api/v1/lottery/buy-again", {
          method: "POST",
          body: JSON.stringify({ transactionId }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then(async (response) => {
            if (response.ok) {
              return response.json();
            }
            const text = await response.json();
            throw new Error(text.message || text);
          })
          .then(async (dt) => {
            if (dt?.parsedTickets && dt.parsedTickets.length > 0) {
              for (const ticket of dt.parsedTickets) {
                await addToCart(ticket);
              }
            }
          })
          .finally(() => setLoading(false));
      } catch (error) {
        console.log(error);
        notify("error", error.message);
      }
    },
    [token, addToCart, notify]
  );

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "actions",
        enableColumnFilter: false,
        enableGrouping: false,
        enableSorting: false,
        header: t("lotteries.fields.action"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        Cell: ({ cell }) => (
          <>
            <button
              className="btn btn-simbalotto-orange text-uppercase mb-2"
              onClick={() => {
                history.push(`/my-ticket/${cell.row.original.transaction_id}`);
              }}
            >
              {t("lotteries.fields.view")}
            </button>

            <button
              className="btn btn-info text-uppercase"
              onClick={() => buyAgain(cell.row.original.transaction_id)}
              disabled={loading}
            >
              {t("lotteries.fields.buy-again")}
            </button>
          </>
        ),
      },
      {
        accessorKey: "transaction_id",
        header: t("lotteries.fields.ticket-id"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
      },
      {
        accessorKey: "lottery",
        header: t("lotteries.fields.lottery"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        filterVariant: "select",
        filterSelectOptions: lotteries,
        Cell: ({ cell }) =>
          lotteries.find((lottery) => lottery.value === cell.getValue())?.text,
      },
      {
        accessorKey: "purchased_at",
        accessorFn: (row) => new Date(row.purchased_at),
        id: "purchased_at",
        header: t("lotteries.fields.purchase-date"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        Cell: ({ cell }) => cell.getValue()?.toLocaleString("en-GB"),
        Header: ({ column }) => <em>{column.columnDef.header}</em>,
        Filter: ({ column }) => <DatePickerCustom {...column} />,
      },
      {
        accessorKey: "draw_date",
        accessorFn: (row) => row.draw_date && new Date(row.draw_date),
        id: " draw_date",
        header: t("lotteries.fields.draw-date"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        Cell: ({ cell }) =>
          cell.row?.original?.draw_date
            ? new Date(cell.row.original.draw_date).toLocaleDateString("en-GB")
            : t("lotteries.fields.unconfirmed"),
        Header: ({ column }) => <em>{column.columnDef.header}</em>,
        Filter: ({ column }) => <DatePickerCustom {...column} />,
      },
      {
        accessorKey: "price",
        header: t("lotteries.fields.amount"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        Cell: ({ cell }) =>
          cell.row.original.price.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
          }),
      },
      {
        accessorKey: "status",
        header: t("lotteries.fields.status"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        filterVariant: "select",
        filterSelectOptions: statuses,
        Cell: ({ cell }) => {
          const status = cell.getValue();
          return (
            <span
              className={`badge ${
                status === "won"
                  ? "badge-success"
                  : status === "pending-draw"
                  ? "badge-warning"
                  : "badge-danger"
              }`}
            >
              {statuses.find((s) => s.value === status)?.text}
            </span>
          );
        },
      },
      {
        accessorKey: "win_amount",
        header: t("lotteries.fields.win-amount"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        Cell: ({ cell }) =>
          cell.row.original?.win_amount?.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
          }),
      },
    ],
    [t, lotteries, statuses, loading, history, buyAgain]
  );

  return (
    <TableComponent
      title={t("lotteries.my-lotteries")}
      columns={columns}
      url="/api/v1/userLotteries/all?"
    />
  );
};

export default MyTickets;
